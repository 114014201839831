html {
    background-color: rgb(247, 249, 255);
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Manrope', sans-serif;
}
