.project-card {
    flex: 1 0 30%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 32px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 32px;
    border-width: 3px;
	border-style: solid;
    border-radius: 20px;
	border-color: rgb(232, 232, 255);
}

.project-photo {
    width: 30%;
    border-radius: 100%;
    margin: 12px;
}

.project-info {
    margin-right: 24px;
}

.project-title {
    font-size: 20px;
    font-weight: 600;
}

.project-tags {
    display: flex;
    max-width: 80%;
    flex-wrap: wrap;
}

.tag {
    padding: 4px 8px;
    background-color: rgb(233, 233, 253);
    border-radius: 6px;
    margin-right: 6px;
    margin-bottom: 4px;
    color: black;
}

.project-links a {
    text-decoration: none;
    color: black;
}

.project-links {
    margin-top: 6px;
    display: flex;
}

.link-button {
    display: flex;
    align-items: center;
    background-color: rgb(50, 50, 192);
    padding: 4px;
    border-radius: 6px;
    margin-right: 6px;
    color: white;
}

.icon-link {
    margin-right: 4px;
    color: white;
}

.link-button i[class^="fi-rr-"], .link-button i[class*=" fi-rr-"], .link-button span[class^="fi-rr-"], .link-button span[class*="fi-rr-"] {
    margin-bottom: -4px;
}

@media only screen and (max-width: 1024px) {
    .project-photo {
        width: 120px;
    }

    .project-card {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: 16px;
    }

    .project-info {
        margin-left: 0px;
        margin-top: 12px;
    }

    .project-tags {
        max-width: 100%;
    }
}
