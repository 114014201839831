.projects {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.projects-list {
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 720px) {
    .projects-list {
        display: flex;
        flex-direction: column;
    }
}