.header-web{
    display: flex;
    flex: 1;
    align-items: center;
}

.web-option{
    font-size: 20px;
    font-weight: 700;
}

.web-option a{
    text-decoration: none;
    color: rgb(6, 0, 91);
    display: flex;
    align-items: center;
}

.option-icon {
    color: rgb(0, 10, 73);
    display: flex;
    align-items: center;
    margin-right: 3px;
}

.web-option:last-child {
    margin-right: 0px;
}

.header-separator{
    width: 2px;
    height: 24px;
    background-color: rgb(6 0 91);
    border-radius: 4px;
    margin: 0px 12px;
}