.header-mobile {
    height: 180px;
    width: 40vw;
    display: flex;
    flex-direction: column;
}

.close-icon {
    display: flex;
    justify-content: flex-end;
    font-size: 24px;
}

.mobile-options {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    width: 100%;
    height: 100%;
}

.mobile-option {
    font-size: 18px;
    margin: 8px 0px;
    display: flex;
}

.mobile-option a {
    text-decoration: none;
    color: rgb(0, 0, 91);
    display: flex;
}