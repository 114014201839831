.skill-card {
    width: max-content;
    padding: 8px 16px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    margin: 6px;
    border-radius: 8px;
}

.skill-icon {
    font-size: 80px;
    color: rgb(50, 49, 68);
}

.skill-name {
    font-size: 16px;
}

.skill-level {
    z-index: 10;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 165, 0);
    border-radius: 4px;
    color: rgba(255, 255, 255, 0);
    position: absolute;
    font-size: 14px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: all;
    margin-top: -30px;
    padding-bottom: 30px;
    font-weight: 500;
}

.skill-level:hover, .skill-level:focus {
    visibility: visible;
    background-color: rgba(0, 0, 165, 0.701);
    color: white;
    margin-top: 0px;
    padding-bottom: 0px;
    font-weight: 500;
}

@media only screen and (max-width: 720px){

}