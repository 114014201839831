.body {
    padding: 12px;
    margin-top: 48px;
    padding-bottom: 32px;
}

.section-title {
    font-size: 40px;
    font-weight: 700;
}

@media only screen and (max-width: 720px){
    .body {
        margin-top: 0;
    }
}